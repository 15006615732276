import { HydrateOption } from '@core/enums';
import withWidget from '@hoc/withWidget';
import React, { useEffect, useRef } from 'react';
import { useCookie } from 'react-use';
import S from './CookiePanel.styles';
const CookiePanel = ({ acceptCta, content = '', learnMoreCta }) => {
    const containerRef = useRef(null);
    const [value, setCookie] = useCookie('dismiss-cookie-alert');
    useEffect(() => {
        const height = !containerRef.current ? 0 : containerRef.current.clientHeight;
        window.document.documentElement.style.setProperty('--cookie-height', `${height + 10}px`);
    }, [value]);
    if (value === '1') {
        return null;
    }
    return (React.createElement(S.Container, { ref: containerRef },
        React.createElement(S.Inner, null,
            React.createElement(S.CloseButton, { type: "button", onClick: (event) => {
                    event.preventDefault();
                    dismiss();
                } },
                React.createElement("span", null, "Close"),
                React.createElement(S.CloseIcon, null)),
            React.createElement(S.Content, { dangerouslySetInnerHTML: { __html: content } }),
            React.createElement(S.CtaWrapper, null,
                learnMoreCta && (React.createElement(S.Link, { href: learnMoreCta.url, target: learnMoreCta.target }, learnMoreCta.text)),
                acceptCta && (React.createElement(S.Button, { onClick: async (event) => {
                        event.preventDefault();
                        accept();
                    } }, acceptCta.text))))));
    async function accept() {
        if (!acceptCta?.url) {
            return;
        }
        try {
            const res = await fetch(acceptCta.url, {
                method: 'POST',
                body: JSON.stringify({ accept: true }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) {
                console.error(`LoginError`, res.status, res.statusText, res);
                return;
            }
            dismiss();
        }
        catch (error) {
            console.error(`LoginError`, error);
        }
    }
    function dismiss() {
        setCookie('1', { expires: 30, path: '/' });
    }
};
export default withWidget(CookiePanel, 'CookiePanel', { hydrate: HydrateOption.Always });
