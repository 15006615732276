import { ReactComponent as SvgClose } from '@img/icons/search-close.svg';
import { fonts } from '@helpers/fonts';
import { Device, until } from '@helpers/media';
import srOnly from '@helpers/srOnly';
import styled, { css } from 'styled-components';
import { SITE_WIDTH, SITE_PADDING, SITE_PADDING_MOBILE, } from '@stories/Components/Containers/SiteWide/SiteWide.styles';
const Container = styled.section `
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  background: #575757;
  border: 0;
  border-top: 9px solid #6f9d20;
  color: #fff;
  margin: 0;
  padding: 32px 0;
  width: 100%;
  z-index: 8003;

  @media ${until(Device.Tablet)} {
    padding: 16px 0;
  }
`;
const Inner = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: calc(${SITE_WIDTH}px + (${SITE_PADDING}px * 2));
  padding: 0 ${SITE_PADDING}px;

  @media ${until(Device.TabletLarge)} {
    max-width: none;
    padding: 0 ${SITE_PADDING_MOBILE}px;
  }

  @media ${until(Device.Tablet)} {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const CloseButton = styled.button.attrs({ type: 'button' }) `
  position: absolute;
  top: 0;
  right: ${SITE_PADDING}px;
  display: block;
  background: none;
  border: 0;
  color: #ffffff;
  height: 24px;
  margin: 0;
  padding: 0;
  width: 24px;

  &:focus,
  &:hover {
    background: none;
    border: 0;
    color: #ffffff;
  }

  > span {
    ${srOnly}
  }

  @media ${until(Device.TabletLarge)} {
    right: ${SITE_PADDING_MOBILE}px;
  }
`;
const CloseIcon = styled(SvgClose) ``;
const Content = styled.div `
  display: block;
  flex: 1 1 auto;
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  max-width: 965px;
  padding: 0 24px 0 0;

  @media ${until(Device.Tablet)} {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 16px;
    padding: 0 40px 0 0;
  }
`;
const CtaWrapper = styled.div `
  display: flex;
  flex: 0 0 auto;
  margin: 0 auto;
  padding: 0 40px 0 0;

  @media ${until(Device.Tablet)} {
    margin: 0;
    padding: 0;
  }
`;
const ctaStyles = css `
  display: inline-flex;
  align-items: center;
  ${fonts.DaxPro.Bold};
  font-size: 16px;
  height: 44px;
  line-height: 26px;
  justify-content: center;
  margin: 0;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  transition: background-color ease 0.3s, border-color ease 0.3s, color ease 0.3s;
`;
const Link = styled.a `
  ${ctaStyles}
  background: none;
  border: 1px solid #fff;
  color: #fff;

  &:focus,
  &:hover {
    background: #a2b952;
    border: 1px solid #fff;
    color: #fff;
    text-decoration: none;
  }
`;
const Button = styled.button.attrs({ type: 'button' }) `
  ${ctaStyles}
  background: #6f9d20;
  border: 0;
  color: #fff;
  margin-left: 32px;

  &:focus,
  &:hover {
    background: #a2b952;
    color: #fff;
    text-decoration: none;
  }
`;
export default {
    Button,
    CloseButton,
    CloseIcon,
    Container,
    Content,
    CtaWrapper,
    Inner,
    Link,
};
